import React from 'react'
import { graphql } from 'gatsby'
import sortBy from 'lodash/sortBy'
import Layout from '../components/Layout'
import SimpleHero from '../components/SimpleHero'
import ProjectCardList from '../components/ProjectCardList'
import ProjectCard from '../components/ProjectCard'
import { Helmet } from 'react-helmet'
import Container from '../components/Container'
import Pagination from '../components/Pagination'
import SEO from '../components/SEO'
import config from '../utils/siteConfig'

const Projects = ({ data, pageContext }) => {
  const projects = sortBy(
    data.contentfulProjectCategory.project,
    'completedDate'
  ).reverse()
  const { title, slug } = data.contentfulProjectCategory
  const numberOfProjects = projects.length
  const skip = pageContext.skip
  const limit = pageContext.limit
  const currentPage = pageContext.currentPage
  const isFirstPage = currentPage === 1

  return (
    <Layout>
      {/* <SEO /> */}
      {!isFirstPage && (
        <Helmet>
          <title>{`${config.siteTitle} - Projects: Page ${currentPage}`}</title>
        </Helmet>
      )}
      <Container>
        <SimpleHero
          headline={`${numberOfProjects} Posts in topic: "${title}"`}
          pageName={title}
        />

        <ProjectCardList>
          {projects.map((project) => {
            return <ProjectCard key={project.id} {...project} />
          })}
        </ProjectCardList>
      </Container>
      <Pagination context={pageContext} urlBase="projects" />
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    contentfulProjectCategory(slug: { eq: $slug }) {
      title
      id
      slug
      project {
        id
        name
        slug
        content {
          childMarkdownRemark {
            html
            excerpt(pruneLength: 80)
          }
        }
        completedDate(formatString: "MMMM DD, YYYY")
        category {
          title
          slug
        }
        coverImage {
          title
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_noBase64
          }
        }
        images {
          title
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_noBase64
          }
        }
        elementsOfDesign
        services
      }
    }
  }
`

export default Projects
